"use strict";
window.onload = () => {
    //
    const loadingScreen = document.getElementById('loadingScreen');
    if (loadingScreen != null) {
        //
        loadingScreen.classList.add('hide');
        setTimeout(() => loadingScreen.classList.add('d-none'), 350);
    }
    //
    document.body.style.overflow = 'auto';
};
