"use strict";
/**
 *
 * @param id
 */
function nextStep(id) {
    //
    const title = document.getElementById('stepTitle');
    const titleSm = document.getElementById('stepTitleSm');
    const steps = document.querySelectorAll('.wrap_step');
    const nextStep = document.querySelector(`[data-stepNumber="${id}"]`);
    const currentStep = nextStep === null || nextStep === void 0 ? void 0 : nextStep.previousElementSibling;
    //
    if (steps.length > 0) {
        //
        nextStep === null || nextStep === void 0 ? void 0 : nextStep.classList.add('active');
        currentStep === null || currentStep === void 0 ? void 0 : currentStep.classList.add('completed');
        currentStep === null || currentStep === void 0 ? void 0 : currentStep.classList.remove('active');
        // console.log(nextStep?.getAttribute('data-stepTitle'))
        steps.forEach(step => {
            //
            step.classList.remove('show');
            if (step.getAttribute('id') == id) {
                step.classList.add('show');
                if (title === null || title === void 0 ? void 0 : title.textContent)
                    title.textContent = step.getAttribute('data-stepTitle') || '';
                // if (titleSm?.textContent) titleSm.textContent = step.getAttribute('data-stepTitle') || ''
            }
        });
    }
}
/**
 *
 * @param id
 */
function completed(id) {
    //
    const last = document.querySelector(`[data-stepNumber="${id}"]`);
    //
    if (last) {
        //
        last === null || last === void 0 ? void 0 : last.classList.add('completed');
        last === null || last === void 0 ? void 0 : last.classList.remove('active');
        //
        elementToggle('completeOrg');
    }
}
