"use strict";
/**
 *
 */
function payNow(id, alertId) {
    // init
    const payBox = document.getElementById(id ? id : 'payBox');
    //
    payBox === null || payBox === void 0 ? void 0 : payBox.classList.toggle('show');
    popUpPay === null || popUpPay === void 0 ? void 0 : popUpPay.classList.add('hasAlertMsg');
    elementToggle(alertId ? alertId : 'alertMsg', 120);
}
/**
 *
 * @param ev
 */
function selectPaymentMethod(ev) {
    /**
     * init
     */
    const li = ev.currentTarget;
    const btns = document.querySelectorAll(`[data-payment-type]`);
    // ES6 Destruction
    const { parentElement } = li;
    /**
     *
     */
    if ((parentElement === null || parentElement === void 0 ? void 0 : parentElement.children) instanceof HTMLCollection && (parentElement === null || parentElement === void 0 ? void 0 : parentElement.children.length) > 0) {
        /**
         * remove active class from all children
         */
        Array.from(parentElement === null || parentElement === void 0 ? void 0 : parentElement.children).forEach(li => li.classList.remove('active'));
        /**
         * then adding it in current child
         */
        li.classList.add('active');
        /**
         * show right btn
         */
        Array.from(btns).forEach(btn => {
            btn.classList.add('d-none');
            if (btn.getAttribute(`data-payment-type`) == li.id) {
                if (btn.disabled)
                    btn.disabled = false;
                btn.classList.remove('d-none');
            }
        });
    }
}
/**
 *
 */
function hideAlertBox(alertId) {
    const el = document.getElementById(alertId ? alertId : 'alertMsg');
    el === null || el === void 0 ? void 0 : el.classList.remove('show');
    popUpPay === null || popUpPay === void 0 ? void 0 : popUpPay.classList.remove('hasAlertMsg');
}
/**
 *
 * @param ev
 */
function handlerFastDonateType(ev) {
    /**
     * init
     */
    const li = ev.target;
    const description = document.getElementById('fastDonateTypeDes');
    // ES6 Destruction
    const { children } = document.getElementById('fastDonateTypes');
    /**
     *
     */
    if (children instanceof HTMLCollection && children.length > 0) {
        /**
         * remove active class from all children
         */
        Array.from(children).forEach(li => li.classList.remove('active'));
        /**
         * then adding it in current child
         */
        li.classList.add('active');
        /**
         * then get data-type-description fro child to set it in heading
         */
        description.textContent = li.getAttribute('data-type-description');
    }
}
/**
 *
 */
function toggleFastDonate(show) {
    if (show) {
        /**
         *
         */
        fastDonate === null || fastDonate === void 0 ? void 0 : fastDonate.classList.add('show');
        if ((fastDonate === null || fastDonate === void 0 ? void 0 : fastDonate.classList.contains('collapsed')) == true)
            fastDonate === null || fastDonate === void 0 ? void 0 : fastDonate.classList.remove('collapsed');
        /**
         * show FastDonateTypes
         * and hide fastDonateTitle
         */
        warpFastDonateTypes.classList.remove('d-none');
        fastDonateTitle.classList.add('d-none');
        /**
         *
         */
        toggleDonateDetails(true);
        handlerTogglePopUpPay();
    }
    else
        fastDonate === null || fastDonate === void 0 ? void 0 : fastDonate.classList.remove('show');
}
/**
 *
 */
function handlerFastDonatePaymentBox() {
    togglePaymentBox(true);
    toggleDonateDetails(false);
}
/**
 *
 */
function handlerDonateNow(ev) {
    /**
     *
     */
    const el = ev.currentTarget;
    /**
     * hide FastDonateTypes from donate Details
     */
    warpFastDonateTypes.classList.add('d-none');
    /**
     * show fastDonateTitle
     */
    fastDonateTitle.classList.remove('d-none');
    fastDonateTitle.textContent = el.getAttribute('data-title-payment');
    toggleDonateDetails(true);
    handlerTogglePopUpPay();
}
/**
 *
 */
function toggleDonateDetails(show) {
    /**
     *
     */
    const el = document.getElementById('donateDetails');
    if (show)
        el === null || el === void 0 ? void 0 : el.classList.add('show');
    else
        el === null || el === void 0 ? void 0 : el.classList.remove('show');
}
/**
 *
 */
function togglePaymentBox(show, payBoxId) {
    /**
     *
     */
    const el = document.getElementById(payBoxId ? payBoxId : 'payBox');
    if (show)
        el === null || el === void 0 ? void 0 : el.classList.add('show');
    else
        el === null || el === void 0 ? void 0 : el.classList.remove('show');
}
/**
 *
 */
function hideContainerPayment(containerId, alertId) {
    /**
     * hide all children
     */
    hideAlertBox(alertId);
    togglePaymentBox(false);
    toggleDonateDetails(false);
    toggleFastDonate(false);
    handlerTogglePopUpPay(containerId);
}
/**
 *
 */
function showContainerPayment() {
    togglePaymentBox(true);
    handlerTogglePopUpPay();
}
/**
 *
 */
function handlerTogglePopUpPay(id) {
    /**
     * toggle fade element
     */
    elementToggle(id ? id : 'popUpPay', 120).then(res => {
        /**
         * toggle stop or run scroll bar
         */
        if (res)
            document.body.classList.add('overflow-hidden');
        else
            document.body.classList.remove('overflow-hidden');
    });
}
/**
 *
 * @param el
 */
function toggleGiftDonationBox(el) {
    //
    const input = el.currentTarget;
    const giftDonationBox = document.getElementById('giftDonationBox');
    //
    if (input.checked)
        giftDonationBox === null || giftDonationBox === void 0 ? void 0 : giftDonationBox.classList.add('d-block');
    else
        giftDonationBox === null || giftDonationBox === void 0 ? void 0 : giftDonationBox.classList.remove('d-block');
}
/**
 *
 * @param cardNumber
 * @returns
 */
function formatCreditCardNumber(ev) {
    const el = ev.target;
    const cardNumber = el.value;
    // Remove any non-digit characters from the input
    const cleanedNumber = cardNumber.replace(/\D/g, '');
    // Use a regular expression to add a space every four digits
    const formattedNumber = cleanedNumber.replace(/(\d{4})(?=\d)/g, '$1 ');
    //
    el.value = formattedNumber;
}
