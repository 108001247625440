"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 *
 * @param ev Event
 * @returns
 */
function stopPropagation(ev) {
    if (ev instanceof Event) {
        ev.stopPropagation();
        return null;
    }
    throw new Error('event is not undefined');
}
/**
 *
 * @param elementId id Element
 * @param fadeOut daily mile Seconds  to remove class show
 */
function elementToggle(elementId, fadeOut = 300) {
    return __awaiter(this, void 0, void 0, function* () {
        // get
        const el = document.getElementById(elementId);
        //
        if (el == null)
            throw new Error('Element id is null');
        /**
         *
         */
        return new Promise((res) => {
            //
            if (!el.classList.contains('show')) {
                //
                el.classList.add('show');
                setTimeout(() => {
                    el.classList.add('animation');
                    res(true);
                }, 1);
            }
            else {
                el.classList.remove('animation');
                const clear = setTimeout(() => {
                    el.classList.remove('show');
                    res(false);
                }, fadeOut);
            }
        });
    });
}
/**
 *
 * @param value text for copy
 * @returns promise will return string or Exception
 */
function ClipboardHandler(value) {
    return __awaiter(this, void 0, void 0, function* () {
        const { clipboard } = navigator || {};
        if (clipboard) {
            try {
                yield clipboard.writeText(value);
                const read = yield clipboard.readText();
                return read;
            }
            catch (err) {
                throw err;
            }
        }
        else
            alert("Your Browser Need Update Because it's older");
    });
}
function copyUrl() {
    //
    const url = document.getElementById('url');
    const copiedAlert = document.getElementById('copiedAlert');
    //
    if (url === null || url === void 0 ? void 0 : url.textContent) {
        //
        ClipboardHandler(url.textContent.trim())
            .then(res => {
            // copied
            copiedAlert === null || copiedAlert === void 0 ? void 0 : copiedAlert.classList.add('d-flex');
            //
            const clear = setTimeout(() => copiedAlert === null || copiedAlert === void 0 ? void 0 : copiedAlert.classList.remove('d-flex'), 400);
        });
    }
}
