"use strict";
/**
 *
 * @param el
 */
function toggleSharingIcons(el) {
    //
    const element = el.currentTarget;
    //
    element.classList.toggle('show');
}
function toggleSharingBox(id) {
    elementToggle('appPopUpSharing', 110);
}
