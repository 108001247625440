"use strict";
/**
 * vars
 */
var popUpPay = document.getElementById('popUpPay');
var fastDonate = document.getElementById('fastDonate');
var fastDonateTitle = document.getElementById('fastDonateTitle');
var warpFastDonateTypes = document.querySelector('.wrap_fast_donate_types');
/**
 *
 * @param ev
 */
window.onscroll = (ev) => {
    /**
     * documentElement
     */
    const doc = document.documentElement;
    if (doc.scrollTop >= 100) {
        fastDonate === null || fastDonate === void 0 ? void 0 : fastDonate.classList.add('collapsed');
        fastDonate === null || fastDonate === void 0 ? void 0 : fastDonate.classList.remove('show');
    }
    else
        fastDonate === null || fastDonate === void 0 ? void 0 : fastDonate.classList.remove('collapsed');
};
