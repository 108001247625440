"use strict";
function toggleSideMenu(event) {
    //
    const elMenu = document.getElementById('sideMenu');
    if (elMenu == null)
        throw new Error('Element is null');
    //
    if (!elMenu.classList.contains('show')) {
        //
        elMenu.classList.add('show');
        setTimeout(() => {
            elMenu.classList.add('animation');
        }, 1);
    }
    else {
        elMenu.classList.remove('animation');
        const clear = setTimeout(() => {
            elMenu.classList.remove('show');
        }, 300);
    }
}
