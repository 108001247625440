/**
 * 
 * @param id 
 */
function nextStep(id: string) {

    //
    const title = document.getElementById('stepTitle');
    const titleSm = document.getElementById('stepTitleSm');
    const steps = document.querySelectorAll('.wrap_step');
    const nextStep = document.querySelector(`[data-stepNumber="${id}"]`);
    const currentStep = nextStep?.previousElementSibling;
    

    //
    if (steps.length > 0) {
        
        //
        nextStep?.classList.add('active');
        currentStep?.classList.add('completed');
        currentStep?.classList.remove('active');

        // console.log(nextStep?.getAttribute('data-stepTitle'))
        steps.forEach(step => {

            //
            step.classList.remove('show')

            if(step.getAttribute('id') == id) {
                step.classList.add('show')
                if (title?.textContent) title.textContent = step.getAttribute('data-stepTitle') || ''
                // if (titleSm?.textContent) titleSm.textContent = step.getAttribute('data-stepTitle') || ''
            }
            

        })

    }

}


/**
 * 
 * @param id 
 */
function completed(id: string) {

    //
    const last = document.querySelector(`[data-stepNumber="${id}"]`);

    //
    if (last) {
        
        //
        last?.classList.add('completed');
        last?.classList.remove('active');

        //
        elementToggle('completeOrg')
    }

}