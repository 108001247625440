"use strict";
let fileName = '';
/**
 *
 * @param ev
 * @param idFilePlaceholder element that will show file name
 */
function fileUpload(ev, idFilePlaceholder) {
    //
    const input = ev.target;
    const filePlaceholder = document.getElementById(idFilePlaceholder);
    //
    if (input.files != null && input.files.length > 0) {
        //
        const file = input.files.item(0);
        //
        if (filePlaceholder != null) {
            // save default placeholder
            if (fileName == '')
                fileName = filePlaceholder.textContent || '';
            //
            filePlaceholder.textContent = (file === null || file === void 0 ? void 0 : file.name) || '';
        }
    }
    else if (filePlaceholder != null)
        filePlaceholder.textContent = fileName;
}
