/**
 * vars
 */
var popUpPay = document.getElementById('popUpPay');
var fastDonate = document.getElementById('fastDonate');
var fastDonateTitle = document.getElementById('fastDonateTitle') as HTMLHeadingElement;
var warpFastDonateTypes = document.querySelector('.wrap_fast_donate_types') as HTMLDivElement;


/**
 * 
 * @param ev 
 */
window.onscroll = (ev: Event) => {

    /**
     * documentElement
     */
    const doc = document.documentElement;

    if (doc.scrollTop >= 100) {
        fastDonate?.classList.add('collapsed')
        fastDonate?.classList.remove('show')
    }
    else fastDonate?.classList.remove('collapsed')

}